import * as React from 'react'
import { FunctionComponent, ReactNode } from 'react'
import HtmlToReact from 'html-to-react'
import {
  BlinkOptions,
  HtmlNode,
  HtmlViewProps,
  ProcessInstruction,
  ProcessNodeRuleForTagLookup,
} from './types'

import Blink from '@components/Blink'

import config from '@config'
import styled, { css } from 'styled-components'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

const {
  search: { textHighlightClass, textHighlightColor },
} = config

const StyledBlink = styled(Blink)<{ hasInvertedColors: boolean }>`
  ${({
    theme: {
      color: {
        tertiary: { grey100 },
        primary: { primary02, primary01, blickRed },
      },
    },
    hasInvertedColors,
  }) => css`
    color: ${hasInvertedColors ? primary02 : primary01};
    text-decoration: underline;

    &:hover {
      color: ${hasInvertedColors ? grey100 : blickRed};
    }
  `}
`

const htmlToReactParser = new HtmlToReact.Parser()
const processDefaultNode = new HtmlToReact.ProcessNodeDefinitions(React)
  .processDefaultNode

const getProcessingInstructions = (
  allowedHtmlTags: string[],
  processNodeRuleForTag: ProcessNodeRuleForTagLookup = {},
  blinkOptions: BlinkOptions = { inTextBox: false, brandedBox: false },
  hasInvertedColors: boolean
): Array<ProcessInstruction> => {
  return [
    {
      // process highloghted text in search
      shouldProcessNode: (node) => {
        return (
          node?.type === 'tag' &&
          node?.name === 'span' &&
          node?.attribs?.class === textHighlightClass &&
          (node?.children[0] as unknown as HtmlNode)?.type === 'text' &&
          allowedHtmlTags.includes(node?.name)
        )
      },
      processNode: (_, children: ReactNode[], index) => {
        const inlineStyle = {
          backgroundColor: textHighlightColor,
          padding: '0px 0 0 0',
        }

        return (
          <span key={`search-item-${children[0]}-${index}`} style={inlineStyle}>
            {children[0]}
          </span>
        )
      },
    },
    {
      // process the text nodes
      shouldProcessNode: (node) => {
        return node.type === 'text'
      },
      processNode: processDefaultNode,
    },
    {
      // process the link html tags
      shouldProcessNode: (node) => {
        return (
          node.type === 'tag' &&
          node.name === 'a' &&
          allowedHtmlTags.includes(node?.name)
        )
      },
      processNode: (node, children, index) => {
        // @ts-ignore - Wrong concat types
        const allChildren = node.data ? [node.data].concat(children) : children
        const { href, target } = node.attribs
        const { inTextBox, brandedBox } = blinkOptions

        if (!href) {
          return null
        }

        const regex = /\d+/g
        const articleId = href.match(regex)?.join('')

        if (!allChildren) {
          return null
        }

        return (
          <StyledBlink
            href={href}
            target={target}
            rel={node.attribs.rel}
            key={index}
            inTextBox={inTextBox}
            brandedBox={brandedBox}
            targetArticleId={articleId}
            hasInvertedColors={hasInvertedColors}
            inlineArticle>
            {allChildren}
          </StyledBlink>
        )
      },
    },

    {
      // process the allowed html tags
      shouldProcessNode: (node) => {
        return (
          node.type === 'tag' &&
          node.name &&
          allowedHtmlTags.includes(node?.name)
        )
      },
      processNode: (node, children, index, ...props) => {
        const nodeRuleForTag = processNodeRuleForTag[node.name]
        if (nodeRuleForTag && nodeRuleForTag.processNode) {
          return nodeRuleForTag.processNode(
            processDefaultNode,
            node,
            children,
            index,
            nodeRuleForTag.meta,
            ...props
          )
        }
        return processDefaultNode(node, children, index, ...props)
      },
    },

    {
      // process the unallowed html tags (convert unallowed HTML tags to span and render their children)
      shouldProcessNode: (node) => {
        return (
          node.type === 'tag' &&
          node.name &&
          !allowedHtmlTags.includes(node?.name)
        )
      },
      processNode: (node, children, index) => {
        // NOTE: including node.data in children list is taken from HTMLView, not sure if it is needed and ever set for html tags with children
        // @ts-ignore - Wrong concat types
        const allChildren = node.data ? [node.data].concat(children) : children
        return <span key={index}>{allChildren}</span>
      },
    },

    {
      // do not process anything else
      shouldProcessNode: () => false,
    },
  ]
}

const parseContent = (
  content: string,
  allowedHtmlTags: string[],
  processNodeRuleForTag: ProcessNodeRuleForTagLookup,
  blinkOptions: BlinkOptions,
  hasInvertedColors: boolean
) => {
  const isValidNode = () => true
  return htmlToReactParser.parseWithInstructions(
    content,
    isValidNode,
    getProcessingInstructions(
      allowedHtmlTags,
      processNodeRuleForTag,
      blinkOptions,
      hasInvertedColors
    )
  )
}

const HTMLView: FunctionComponent<HtmlViewProps> = ({
  content = '',
  allowedHtmlTags = [],
  processNodeRuleForTag = {},
  inTextBox,
  brandedBox,
}) => {
  const hasInvertedColors = useHasInvertedColors()

  if (content === '') {
    return null
  }
  const parsedContent = parseContent(
    content,
    allowedHtmlTags,
    processNodeRuleForTag,
    { inTextBox: !!inTextBox, brandedBox: !!brandedBox },
    hasInvertedColors
  )

  return <>{parsedContent}</>
}

export default HTMLView
